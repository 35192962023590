// Global Overrides Template Level Only
@import 'node_modules/swiper/swiper.scss';

.custom-file-label:after{
	content:"Scegli file"
}
#importExcelFileModal .modal-header .close {display:none;}
//------- ng-accordion -------
.accordion {
  .card {
    margin: 0;
    background-color: transparent !important;

    &:not(:last-of-type) {
      .card-header {
        border-bottom: 1px solid $border-color;
      }
    }

    &:last-of-type {
      .card-header button {
        // padding-bottom: 0;
      }

      .card-body {
        // padding-bottom: 0;
      }
    }

    .card-header {
      padding: 0;

      button {
        padding: 1rem;
        width: 100%;
        text-align: start;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

// for dark and transparent layouts
html body.layout-dark {
  .accordion .card {
    box-shadow: none;
  }

  &:not(.layout-transparent) {
    .accordion .card .card-header {
      border-color: $dl-border-color;
    }
  }

  &.layout-transparent {
    .accordion .card .card-header {
      border-color: $tl-border-color;

      button:not([disabled]) {
        color: map-get($primary-color, lighten-4);
      }
    }
  }
}



//------- horizontal timeline -------
.cd-horizontal-timeline .events-content li {
  transform: translateX(0);
}



//------- time picker -------
.ngb-tp-input-container button:hover {
  box-shadow: none;
}


//------- Chartist Tooltip -------
.chartist-tooltip:not(.tooltip-show) {
  padding: 0;

  &:before {
    border: 0;
  }
}

company-profile-page .card, 
user-profile-page .card {
	// background-color: #ab8f6985 !important;
	background-color: white !important;
}

a {
	color: map-get($primary-color, "darken-4");
}

body {
	overflow-x: hidden;
}



.accordion .card {
    margin-top: 10px;
}
.accordion .card .card-header {
	// border-radius: 10px;
    // background-color: #f5df19e6 !important;
    background-color: #feeed1 !important;
}

.card-body {
	padding-right: 0px;
	background-color: #feeed1;
}

.accordion .card .card-header button {
	padding: 0px 0px 0px 15px;
	// padding: 1rem;
    // width: -webkit-fill-available;
    // text-align: start;
    // margin-left: 4rem;
    // background-color: #fab726;
    // border-radius: 2rem 0px 0px 2rem;
}

.btn-link, .btn-link:hover {
	color: white;
}

.btn.btn-link i {
	transition-duration: 0.5s;
}

.btn.btn-link:not(.collapsed) i {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
	display: inline-block;
	
}

.ng-select .ng-select-container {
	background-color: #daebcc;
	color: #d82239;
	height: 4rem;
	border-radius: 2rem;
	padding-left: 3rem;
	font-size: 1.2rem;
}

.ng-select.ng-select-opened>.ng-select-container , .ng-select.ng-select-opened{
	background-color: #daebcc;
	color: #d82239;
	height: 4rem;
	border: none;
	border-radius: 2rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	font-size: .9em;
    margin-bottom: 5px;
    background-color: #d82239;
    border-radius: 15px;
    margin-right: 5px;
    border: 1px solid #d82239;
    padding: 0px;
	color: white;
}

.ng-select .ng-value-icon, .ng-select .ng-value-icon:hover {
	background-color: #d82239 !important;
	border-radius: 50%;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
	line-height: 3rem;
}
.ng-placeholder ::placeholder {
	color: #d82239 !important;
	opacity: 1; /* Firefox */
}


.divider {
	background-color: #feedcc;
    height: 1rem;
    width: 100%;
    border-radius: 0.5rem;
	position: relative;
	.inner {
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: #fab726;
		height: 1rem;
		width: 80%;
		border-radius: 0.5rem;
	}

}

.menu-item {
	font-size: 2rem;
}	
.text-color-green {
	color: #90c367 !important;
}
.text-color-red {
	color: #d82239 !important;
}
.text-color-yellow {
	color: #fab726 !important;
}
.text-color-blue {
	color: #1f8ba7 !important;
}

.btn-color-blue,  .btn-color-yellow, .btn-color-green {
	color: white;
	font-size: 1rem;
	font-weight: bolder;
    border: none;
}
.btn-color-blue {
	background-color: #1f8ba7;
} 
.btn-color-yellow {
	background-color: #fab726;
}
.btn-color-green{
	background-color: #91c467;
}


@media only screen and (max-width: 768px) {
	
	.padding-mobile-dev{
		padding-left: 2rem;
		padding-right: 2rem;
	}
	
}

ngb-accordion .btn-link:disabled {
	color: white !important;
}

ngb-accordion .btn:disabled {
	opacity: 1;
}
 

.close {
	text-shadow: none !important;
	color: #d82239 !important;
	opacity: 1 !important;
	font-size: 3rem;
}


.popup-table {
	background-color: #e1eed8;
	margin: 30px;
	border: 1px solid darkgray;
	padding: 20px;
}