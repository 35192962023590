page-header {
	// position: fixed;
    // overflow: visible;
    // z-index: 100;
    // background: white;
    // width: 100vw;

	position: sticky;
    overflow: hidden;
    top: 0px;
    z-index: 100;
    background: white;
    background-color: white !important;
}
.first-row {
	background-color: white;
    // height: 80px;
    color: #1c8da8;
    font-size: large;
    font-weight: bold;
	align-items: center;
	border-bottom: 4px solid #91c467;

	a {
		font-size: 0.9rem;
	}
}

.page-header-border {
	border-bottom: 4px solid #91c467;
}


.italy-flag {
	max-height: 3px;
	overflow: hidden;
	background-color: #91c467;
}
.green-bg {
	background-color: transparent;
}
.red-bg {
	background-color: transparent;
}

.last-row {
	display: none;
	// background-color: #AB8f69;
	// height: 60px;
	// color: white;
	// font-size: x-large;
	// font-weight: bold;
    // align-items: center;
}

// .footer-bg {
// 	background-color: #178aa6;
// 	padding-top: 40px;
// 	padding-bottom: 40px;
// }

page-footer {
	color: rgba(255, 255, 255, .75);
	padding-top: 40px;
	font-family: "Roboto", sans-serif;
	p {
		font-size: 13px;
		line-height: 2;
	}
	.logo-switch {
		width: 300px;
	}
	.logo-eu {
		// max-width: 300px;
		margin-right: 20px;
	}
	.icon-social {
		width: 30px;
		margin-right: 30px;
	}
	
	.footer-bg {
		background-color: #178aa6;
		padding-top: 40px;
    	padding-bottom: 40px;
	}
	
	@media screen and (max-width: 575px) {
		.header-content{
			padding: 0px 50px;
		}
		
	}

	.footer-link {
		color: white;
		text-decoration: underline;
		font-size: larger;
		text-transform: uppercase;
	}
}

@media screen and (max-width: 768px){
	
	.padding-mobile-logo{
		padding-left: 2rem;
		padding-right: 2rem;
	}
	
}